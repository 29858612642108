/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

* {
  margin: 0;
  padding: 0;
}

.RiveContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-color: black; */
  margin: 0;
  padding: 0;
}






.main {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
}

h1 {
  margin: 10px;
  font-size: 40px;
  color: rgb(1, 1, 59);
}

.search {
  width: 30%;
}

ul li {
  font-size: 20px;
} 